import React from "react";
import moment from "moment-timezone";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Link, Redirect} from 'react-router-dom';

import apiRequest from "lib/Api";
import withNotify from "lib/NotificationWrapper";
import {getUserData} from "lib/Auth";

import defaultAvatar from "assets/img/default-avatar.png";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import InputError from "../../components/InputError";
import {NotificationService} from '../../../services/NotificatioService'
import SpecialistBriefingModal from "../modals/SpecialistBriefingModal";
class AppointmentShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointment: false,
            userData: getUserData(),
            redirect: '',
            openModalGuest: false,
            guest: '',
            guests: [],
            errors: {},
            working: false
        };
        for (const alert of this.alerts) {
            this.state[alert] = '';
        }
        moment.lang(this.state.userData.language);
        moment.tz.setDefault(this.state.userData.timezone)
    }

    get id() {
        if (this.props.match.params) {
            return this.props.match.params.id;
        }
        return null;
    }

    async componentDidMount() {
        await this.getBriefingData();
        this.fetchAppointment();
    }

    fetchAppointment() {
        return apiRequest("/appointments/" + this.id, {method: "GET"})
            .then(response => this.setState({appointment: response.data.data}))
            .catch(err => this.state({error: err.data.errors[0]}));
    }

    downloadIcs = (e) => {
        e.preventDefault();
        apiRequest("/appointments/" + this.id + "/ics", {method: "GET"}, {blob: true})
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invite.ics');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }

    performAction(action, successMsg, errorMsg, data) {
        this.setState({working: true});

        return apiRequest(`/appointments/${this.id}/${action}`, {method: 'PUT', data}).then(response => {
            this.props.notify({type: 'success', message: successMsg});
            return response.data.data;
        })
            .catch(err => {
                this.props.notify({type: 'error', message: errorMsg});
                throw err;
            })
            .finally(
                response => this.setState({working: false})
            );
    }

    cancelAppointment = (notes) => {
        return this.performAction(
            'cancel',
            window.gettext('Agendamento foi cancelado'),
            window.gettext('Não foi possível cancelar agendamento. Tente mais tarde.')
        );
    }

    noShowAppointment = () => {
        return this.performAction(
            'no-show',
            window.gettext('Agendamento foi cancelado'),
            window.gettext('Não foi possível cancelar agendamento. Tente mais tarde.')
        );
    }

    startAppointment = () => {
        return this.performAction(
            'start',
            window.gettext('Agendamento foi iniciado'),
            window.gettext('Não foi possível iniciar agendamento. Tente mais tarde.')
        );
    }

    completeAppointment = (notes) => {
        return this.performAction(
            'complete',
            window.gettext('Agendamento foi finalizado'),
            window.gettext('Não foi possível finalizar agendamento. Tente mais tarde.'),
            {notes}
        );
    }

    markMissedAppointment = (notes) => {
        return this.performAction(
            'missed',
            window.gettext('Agendamento foi marcado como não compareceu'),
            window.gettext('Não foi possível marcar como não compareceu. Tente mais tarde.'),
            {notes}
        );
    }

    describeStatus(appointment) {
        switch (appointment.situation) {
            case 'pending':
            case 'approved':
                return window.gettext('Atendimento agendado');
            case 'started':
                return window.gettext('Iniciou atendimento');
            case 'done':
                return window.gettext('Atendimento realizado');
            case 'canceled':
                return window.gettext('Atendimento cancelado');
            case 'missed':
                return window.gettext('Não compareceu');
            case 'noshow':
                return window.gettext('Cancelado em menos de 24 horas');
        }
    }

    colorStatus(appointment) {
        switch (appointment.situation) {
            case 'pending':
            case 'approved':
                return 'info';
            case 'started':
                return 'success';
            case 'done':
                return 'success';
            case 'canceled':
            case 'noshow':
                return 'danger';
            case 'missed':
                return 'warning';
        }
    }

    get buttons() {

        return {
            cancel: () => (
                <Button key="cancel" color="danger" onClick={this.confirmCancel}>
                    <i className="fa fa-times"/>
                    {window.gettext('Cancelar')}
                </Button>
            ),
            start: () => (
                <Button key="start" color="info" onClick={this.confirmStart}>
                    <i className="fa fa-play"/>
                    {window.gettext('Iniciar')}
                </Button>
            ),
            complete: () => (
                <Button key="complete" color="info" onClick={this.confirmComplete}>
                    <i className="fa fa-check"/>
                    {window.gettext('Finalizar')}
                </Button>
            ),
            markMissed: () => (
                <Button key="markMissed" color="warning" onClick={this.confirmMarkMissed}>
                    <i className="fa fa-exclamation"/>
                    {window.gettext('Não Compareceu')}
                </Button>
            ),
            chat: appointment => (
                <Button key="chat" color="default" tag={Link} to={`/chat/appointments/${appointment.id}/chat`}
                        target={`${appointment.id}_chat`}
                >
                    <i className="fa fa-comments-o"/>
                    {window.gettext('Chat')}
                </Button>
            ),
            edit: appointment => (
                <Button key="chat" color="default" tag={Link} to={`/admin/appointments/${appointment.id}/edit`}>
                    <i className="fa fa-edit"/>
                    {window.gettext('Alterar')}
                </Button>
            ),
            reschedule: () => (
                <Button key="reschedule" color="info" onClick={this.rescheduleAppointment}>
                    <i className="fa fa-calendar"/>
                    {window.gettext('Re-agendar')}
                </Button>
            ),
            guests: () => (
                <Button key="guests" color="default" onClick={this.modalGuest}>
                    <i className="fa fa-users"/>
                    {window.gettext('Convidados')}
                </Button>
            )
        }
    }

    get stateActions() {
        let defaultActions = {
            canceled: [],
            done: [],
            missed: [],
            started: [],
            noshow: []
        };
        let actions = {};

        if (this.state.userData.role === 'student') {
            actions = {
                approved: ['cancel', 'start'],
            };
        } else if (this.state.userData.role === 'specialist' || this.state.userData.role === 'super_admin') {
            actions = {
                approved: ['cancel', 'start', 'markMissed'],
                started: ['complete', 'cancel', 'markMissed'],
                missed: ['cancel'],
                canceled: ['reschedule']
            };
        }

        if (this.state.appointment.product_rules && this.state.appointment.product_rules.enable_guest) {
            actions.approved.push('guests');
        }

        // return {...defaultActions, ...actions, ...product_rules};
        return {...defaultActions, ...actions};
    }

    renderButtons() {
        let {appointment} = this.state;
        let {situation} = appointment;
        let buttons = this.buttons;

        let actions = this.stateActions[situation];
        actions.push('chat');

        if (this.state.userData.role === 'specialist' || this.state.userData.role === 'super_admin') {
            actions.push('edit');
        }
        if (this.state.userData.id === 360 ) {
            return (<></>)
        }

        return actions.map(action => buttons[action](appointment));
    }

    renderOrganization() {
        if (!this.state.appointment.organization) {
            return (<></>)
        }
        return (
            <>
                <dt>{window.gettext('Empresa')}</dt>
                <dd>{this.state.appointment.organization.name}</dd>
            </>
        )
    }

    handleChange = (event) => {
        this.setState({'guest': event.target.value})
    }

    renderEmpty(message) {
        return (
            <div className="content">
                <Row>
                    <Col md="12" lg="6">
                        <Card>
                            <CardHeader>
                                <h5>{message}</h5>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    renderGuestUsers = (guest) => {

        return (
            <tr>
                <td>{guest.email}</td>
                <td>
                    <Button size='sm' onClick={() => {
                        this.removeGuest(guest);
                    }}>
                        <i className="fa fa-trash"/>&nbsp;
                        {window.gettext('Remover')}
                    </Button>
                </td>
            </tr>
        );
    }

    removeGuest = (guest) => {

        return apiRequest(`/appointments/${this.id}/guest/${guest.uuid}`, {method: "DELETE"})
            .then(response => {
                let {data} = response.data;
                this.setState({'guests': data.all, 'guest': ''})
            });
    }

    modalGuest = () => {
        this.setState({openModalGuest: true});
        this.loadGuest();
    }

    closeModal = () => {
        this.setState({openModalGuest: false});
    }

    addGuestUser = () => {

        this.setState({errors: {}});
        let eregEmail = /\S+@\S+\.\S+/;
        if (!eregEmail.test(this.state.guest) || this.state.guest.length === 0) {
            this.setState({errors: {guest: window.gettext('o e-mail informado é inválido')}});
            throw 'E-mail inválido';
        }

        for (const guestInvited of this.state.guests) {
            if (this.state.guest.toLowerCase() === guestInvited.email.toLowerCase()) {
                this.setState({errors: {guest: window.gettext('o e-mail informado é já está entre os convidados.')}});
                throw 'E-mail inválido';
            }
        }

        this.setState({working: true});

        let params = {
            method: "POST",
            data: {
                email: this.state.guest
            }
        }

        return apiRequest(`/appointments/${this.id}/guest`, params)
            .then(response => {
                let {data} = response.data;
                this.setState({'guests': data.all, 'guest': ''})
                this.setState({working: false});
            });
    }

    loadGuest = () => {

        return apiRequest(`/appointments/${this.id}/guest`, {method: "GET"})
            .then(response => {
                let {data} = response.data;
                this.setState({'guests': data.all, 'guest': ''})
            });
    }

    confirmCancel = () => {

        const dateStart = moment(this.state.appointment.datetime_start);
        const dateNow = moment();

        let message = '';
        let action = 'cancel';
        if (this.state.userData.role === 'student' && dateStart.diff(dateNow, 'days') <= 0) {
            message = window.gettext('Atenção: o cancelamento com menos de 24h de antecedência consome créditos do programa.');
            action = 'noShow';
        }
        this.showConfirmation(
            action,
            {
                title: window.gettext('Cancelar atendimento'),
                confirmBtnText: window.gettext("Confirmar cancelamento"),
                cancelBtnText: window.gettext("Manter atendimento"),
                confirmBtnBsStyle: 'danger'
            },
            window.gettext('Deseja mesmo cancelar este atendimento?'), message
        );
    }

    confirmStart = () => {
        this.showConfirmation(
            'start',
            {
                title: window.gettext('Iniciar atendimento')
            },
            window.gettext('Deseja iniciar este atendimento?')
        );
    }

    confirmComplete = () => {
        this.showConfirmation(
            'complete',
            {
                type: 'input',
                title: window.gettext('Finalizar atendimento'),
                placeholder: window.gettext('Insira aqui notas sobre o atendimento')
            }
        );
    }

    confirmMarkMissed = () => {
        this.showConfirmation(
            'markMissed',
            {
                type: 'input',
                title: window.gettext('Não compareceu'),
                placeholder: window.gettext('Você pode descrever aqui os motivos de não ter sido realizado'),
                confirmBtnBsStyle: 'danger'
            }
        );
    }

    rescheduleAppointment = () => {
        let {appointment: ap} = this.state;
        let url = `/admin/appointments/reschedule/${ap.id}`;
        this.setState({redirect: url});
    }

    get alerts() {
        const actions = ['cancel', 'start', 'markMissed', 'complete', 'working', 'noShow'];
        return actions.map(action => action + 'Alert');
    }

    showConfirmation = (action, props, content = '', message = '') => {
        let actionAlertVar = action + 'Alert';
        let hide = () => {
            this.setState({[actionAlertVar]: null});
        }

        // TODO showConfirm vs working...

        let defaultProps = {
            type: 'warning',
            inputType: 'textarea',
            onCancel: () => hide(),
            confirmBtnBsStyle: "info",
            cancelBtnBsStyle: "default",
            confirmBtnText: window.gettext("Confirmar"),
            cancelBtnText: window.gettext("Cancelar"),
            showCancel: true,
            onConfirm: (input) => {
                hide();
                this.showWorking();
                this[action + 'Appointment'](input)
                    .then(appointment => {
                        this.setState({appointment});
                        NotificationService.updateNotifications();
                        })
                    .catch(err => {
                    })
                    .finally(() => this.hideWorking());
            }
        };
        props = {...defaultProps, ...props};

        const key = Math.random();
        this.setState({
            [actionAlertVar]: (
                <ReactBSAlert {...props} key={key} >
                    {message !== '' ? message : ''}
                    <br />
                    {content}
                </ReactBSAlert>
            )
        });
    }

    showWorking = () => {
        this.showConfirmation('working',
            {showConfirm: false, showCancel: false, disabled: true},
            window.gettext('Aguarde um momento...')
        );
    }

    hideWorking = () => {
        this.setState({workingAlert: null});
    }

    async getBriefingData() {
        const response = await apiRequest(`/specialist-briefings/appointment/${this.id}`, {method: 'GET'})
        this.setState({briefingData: response.data.data});
    }

    renderButtonBriefingData() {
        if (this.state.userData.role === 'specialist' || this.state.userData.role === 'super_admin') {
            if (this.state.briefingData && this.state.briefingData.uuid ) {
                return (
                    <Button onClick={() => this.openBriefingModal()}
                            size={'sm'}
                            className="btn"
                            color="outline-primary"
                            type="button"
                    >
                        {window.gettext('Briefing')}
                    </Button>
                )
            }
        }
    }

    openBriefingModal() {
        this.setState({isOpenBriefingModal: true, textBriefing: this.state.briefingData.briefing})
    }

    closeBriefingModal() {
        this.setState({isOpenBriefingModal: false, textBriefing: ''})
    }
    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);

        const appointment = this.state.appointment;
        if (!appointment) {
            return this.renderEmpty(window.gettext('Carregando...'));
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <>
                {this.alerts.map(alert =>
                    this.state[alert]
                )}
                <div className="content appointment-show-container">
                    <SpecialistBriefingModal
                        open={this.state.isOpenBriefingModal}
                        textBriefing={this.state.textBriefing}
                        disabledForm={true}
                        onClose={() => this.closeBriefingModal()}
                    />
                    <Row>
                        <Col md="12" lg="6">
                            <Card>
                                <CardHeader>
                                    <h5>
                                        {window.gettext('Informações do agendamento')}
                                        <br/>
                                        <small>
                                            <a href='#' onClick={this.downloadIcs}>
                                                <i className='fa fa-calendar'/> Download .ics
                                            </a>
                                        </small>
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <dl>
                                        <dt>
                                            {window.gettext('Usuário')}
                                        </dt>
                                        <dd>{appointment.user_name} {this.renderButtonBriefingData()}</dd>
                                        {this.renderOrganization()}
                                        <dt>
                                            {window.gettext('Especialista')}
                                        </dt>
                                        <dd>{appointment.specialist_name}</dd>
                                        <dt>
                                            {window.gettext('Quando')}
                                        </dt>
                                        <dd>{moment(appointment.datetime_start).format('LLL')}</dd>
                                        <dt>
                                            {window.gettext('Status')}
                                        </dt>
                                        <dd className={'text-' + this.colorStatus(appointment)}>
                                            <b>{this.describeStatus(appointment)}</b>
                                        </dd>
                                    </dl>
                                </CardBody>
                                <CardFooter>
                                    {this.renderButtons()}
                                </CardFooter>
                            </Card>
                        </Col>

                        <Modal isOpen={this.state.openModalGuest} size="lg">
                            <ModalHeader>
                                {window.gettext('Convidados para o encontro')}
                            </ModalHeader>
                            <ModalBody>
                                <form>
                                    <Row>
                                        <Label sm="3">
                                            {window.gettext('Novo Convidado')}
                                        </Label>
                                        <Col sm="9">
                                            <FormGroup className={hasError.guest}>
                                                <Input type="text" name="guest" value={this.state.guest}
                                                       onChange={this.handleChange}/>
                                                <InputError name="guest" errors={this.state.errors}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{textAlign: 'center'}}>
                                        <Col sm="12">
                                            <Button color="primary" disabled={this.state.working}
                                                    onClick={this.addGuestUser}>
                                                {this.state.working ? window.gettext('Aguarde...') : window.gettext('Convidar')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>{window.gettext('Email')}</th>
                                        <th>{window.gettext('Ação')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.guests.map(this.renderGuestUsers)}
                                    </tbody>
                                </Table>
                                <div style={{textAlign: 'center'}}>
                                    <hr/>
                                    <Button onClick={this.closeModal}>
                                        {window.gettext('Fechar')}
                                    </Button>
                                </div>
                            </ModalBody>
                        </Modal>

                        {appointment.notes && (

                            <Col md="12" lg="6">
                                <Card className="card-testimonial">
                                    <CardBody>
                                        <div className="icon icon-primary">
                                            <i className="fa fa-quote-right"/>
                                        </div>
                                        <p className="card-description">
                                            {appointment.notes}
                                        </p>
                                    </CardBody>
                                    <CardFooter>
                                        <CardTitle tag="h4">{appointment.specialist_name}</CardTitle>
                                        <div className="card-avatar">
                                            <a href="#" onClick={e => e.preventDefault()}>
                                                <img
                                                    alt="..."
                                                    className="img"
                                                    src={appointment.specialist_picture || defaultAvatar}
                                                />
                                            </a>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>

                        )}

                    </Row>
                </div>
            </>
        )
    }

}

export default withNotify(AppointmentShow);
